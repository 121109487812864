import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept'] = 'application/json,text/html, */*'
    if (store.getters.token) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    config.url = config.url.replace(config.baseURL + '/', config.baseURL)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.hasOwnProperty('error')) {
      if (res.error.code === 401) {
          store.dispatch('user/resetToken').then(() => {
              location.reload()
          })
      } else {
        return Promise.reject(new Error(res.error.message || 'Error'))
      }
    } else {
      return res
    }
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
export const BASE_URL = process.env.VUE_APP_BASE_API
export default service
