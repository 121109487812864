<template>
    <div id="app">
        <router-view v-if="loaded"/>
    </div>
</template>

<script>

    export default {
        name: 'app',
        data() {
            return {
                loaded: true,
            }
        },
        created() {
            // const loading = this.$loading({
            //     lock: true,
            //     text: '加载中...',
            //     spinner: 'el-icon-loading',
            //     background: 'rgba(0, 0, 0, 0.7)'
            // });
            // this.$store.dispatch('auth/getInfo').then(() => {
            //     this.loaded = true;
            //     loading.close();
            // }).catch(e => {
            //     this.loaded = true;
            //     loading.close();
            // })
        }
    }
</script>

<style>
    *{font-size: 13px}
</style>


