import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import VueSocketIO from "vue-socket.io";

Vue.config.productionTip = false


// Vue.use(new VueSocketIO({
//     debug: true,
//     connection: process.env.VUE_APP_SOCKET_URL,
//     vuex: {
//         store,
//         actionPrefix: 'SOCKET_',
//         mutationPrefix: 'SOCKET_'
//     },
//     options: {
//         transports: ['websocket'],
//         query: {
//             token: store.getters.token
//         },
//         autoConnect: false,
//         path: '/'
//
//     } //Optional options
// }))


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
