import request from '@/utils/request'
import {getToken, setToken, removeToken} from '@/utils/auth'

const getDefaultState = () => {
    return {
        token: getToken(),
        user: {}
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USER: (state, user) => {
        state.user = user
    }

}

const actions = {
    // user login
    login({commit}, userInfo) {
        const {mobile, password} = userInfo
        return new Promise((resolve, reject) => {
            request.post('auth/login', {mobile: mobile.trim(), password: password, type: 1}).then(response => {
                const {data} = response
                commit('SET_TOKEN', data['access_token'])
                setToken(data['access_token'])
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // get user info
    getInfo({commit, state}) {
        return new Promise((resolve, reject) => {
            request.get('profile').then(response => {
                const {data} = response

                if (!data) {
                    reject('验证失败，请重新登陆')
                }
                commit('SET_USER', data)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({commit, state, dispatch}) {
        return new Promise((resolve, reject) => {
            // request.post('auth/logout', {}, {params: state.token}).then(() => {
            //     commit('SET_TOKEN', '')
            //     removeToken()
            //
            //     resolve()
            // }).catch(error => {
            //     reject(error)
            // })
            commit('SET_TOKEN', '')
            removeToken()

            resolve();

        })
    },

    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            removeToken()
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

